<template>
   <ObiText class="obi-nav nav" as="ul" :class="classList" v-bind="attrs" v-on="$listeners">
      <Draggable v-model="navItems">
         <transition-group>
            <ObiText as="li" class="nav-item" v-for="item in navItems" :key="item.id" :draggable="item.checked">
               <ObiText
                  as="a"
                  class="nav-link"
                  @click="inputValue = item.id"
                  :class="{ active: inputValue === item.id }"
               >
                  <ObiText class="d-flex flex-row align-items-baseline">
                     <ObiCheckbox :checked="item.checked" v-if="selectable" @click="item.checked = !item.checked" />
                     <slot class="p-2" name="item" v-if="$slots.item" v-bind:item="item" />
                     <ObiText class="p-2" v-html="item.label" v-else />
                  </ObiText>
                  <OrderIcon v-if="draggable" />
               </ObiText>
            </ObiText>
         </transition-group>
      </Draggable>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';
import Draggable from 'vuedraggable';

import OrderIcon from '@/components/Icons/portals/OrderIcon';

export default {
   name: 'ObiNav',
   components: {
      OrderIcon,
      Draggable,
   },
   props: {
      value: {},
      items: {
         type: Array,
         require: true,
      },
      vertical: {
         type: Boolean,
         default: false,
      },
      pills: {
         type: Boolean,
         default: false,
      },
      draggable: {
         type: Boolean,
         default: false,
      },
      selectable: {
         type: Boolean,
         default: false,
      },
   },
   beforeMount() {
      this.navItems = this.$props.items;
   },
   computed: {
      classList() {
         const classList = [];

         if (this.pills) {
            classList.push('nav-pills');
         }

         if (this.vertical) {
            classList.push('flex-column');
         }

         return classList;
      },

      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },

      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
            this.$emit('navChanged', val);
         },
      },
   },
   data() {
      return {
         navItems: [],
      };
   },
   watch: {
      navItems: {
         deep: true,
         handler() {
            this.$emit('orderChanged', this.navItems);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-nav {
   .nav-item {
      .nav-link {
         cursor: pointer;
      }
   }

   &.--for-obi-eg {
      .nav-item {
         .nav-link {
            color: #777d96;
            border-radius: 0;
            font-size: 1rem;
            padding: 1rem 1.5rem;
            background-color: transparent;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &.active {
               color: $primary;
               background-color: #fff;
               border-bottom-width: 2px;
               border-bottom-color: $primary;
            }
         }

         &:last-child {
            .nav-link {
               border-bottom-color: transparent;
            }
         }
      }
   }
}
</style>
